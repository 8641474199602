
import {Component, Vue} from 'vue-property-decorator';
import ColumnDef from "../common/column-def";
import TableComponent from "../components/TableComponent.vue";
import CustomPageTitle from "../components/CustomPageTitle.vue";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";
import {EmailAddressControllerApi, PagedRequest} from "../client";
import http from "../plugins/http";

@Component({
    components: {
        CustomPageTitle,
        ConfirmationDialog,
        TableComponent
    },
})
export default class EmailManagement extends Vue {
    public emailAddressControllerApi = new EmailAddressControllerApi(undefined, process.env.VUE_APP_API_BASE_URL, http);

    public newEmail: string | null = null;

    public emailHeaders = [
        new ColumnDef({text: this.$t('email.address'), value: 'emailAddress'}),
        new ColumnDef({text: this.$t('email.status'), value: 'status'}),
        new ColumnDef({text: this.$t('email.delete'), value: 'delete'}),
    ];

    public displayStatus = (value: string) => {
        return this.$t(`email.status-type.${value}`);
    }

    private tableDelegate: TableComponent | null = null;

    private showConfirmDeletionDialog: boolean | null = null;
    private confirmDeletionDialogText: any | null = null;
    private selectedEmailAddressDto: any | null = null;

    private deleteRow(item: any) {
        this.selectedEmailAddressDto = item;
        this.confirmDeletionDialogText = this.$t('confirm-dialog.delete-email', {email: item.emailAddress});
        this.showDialog(true);
    }

    private registerNewEmail() {
        if (this.newEmail) {
            this.emailAddressControllerApi.registerEmailAddress({emailAddress: this.newEmail}).then(
                () => {
                    if (this.tableDelegate) {
                        this.tableDelegate.retrieveData();
                    }
                    this.newEmail = null;
                }
            )
        }
    }

    private setTable(table: TableComponent) {
        this.tableDelegate = table;
    }

    private onConfirm() {
        this.emailAddressControllerApi.deleteEmail(this.selectedEmailAddressDto.id).then(
            () => {
                if (this.tableDelegate) {
                    this.tableDelegate.retrieveData();
                }
            }
        )
        this.showDialog(false);
    }

    private showDialog(value: boolean | null) {
        this.showConfirmDeletionDialog = value;
    }

    private retrieveEmails(request: PagedRequest) {
        return this.emailAddressControllerApi.listEmailAddress(request);
    }
}
